@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

html,
body {
  font-family: "Lato", sans-serif;
  max-height: 100%;
  height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

*::-webkit-scrollbar{
  display: none !important;
}

p {
  color: black;
}

h3 {
  color: black;
}
label{
  cursor: pointer;
}
input:focus {
  outline: none;
}

input:focus-visible {
  outline: none;
}

input {
  color: black !important;
}

.theme-shadow {
  box-shadow: 0px 0px 10px #ddd;
}

.seprator {
  background-color: #ddd;
  height: 3px;
  width: 100%;
  position: relative;
  left: 0;
}

.min-height-imp {
  min-height: 0 !important;
}

.modal {
  --tw-bg-opacity: 0.8;
}

.modal-box {
  max-width: 100%;
}

// theme square checkbox
.styled-checkbox {
  position: absolute;
  opacity: 0;

  &+label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  &+label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  &:checked+label:before {
    background: green;
  }

  &:checked+label:after {
    content: "";
    position: absolute;
    left: 5px;
    top: 9px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
      4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
}

//   theme rounded checkbox
.styled-checkbox_rounded {
  position: absolute;
  opacity: 0;

  &+label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  &+label:before {
    content: "";
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 50%;
  }

  &:checked+label:before {
    background: green;
  }

  &:checked+label:after {
    content: "";
    position: absolute;
    left: 5px;
    top: 11px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
      4px -6px 0 white, 4px -8px 0 white;
    transform: rotate(45deg);
  }
}

.modal{
  z-index: 9999;
}

.customisation-seprator {
  height: 1px;
  width: 100%;
  background-color: #ddd;
  margin: 10px 0;
}

.cart-navigation{
  svg{
      width: 22px;
  }
}


a.home-carousel-indicator:active{
  background-color: red;
}
a.home-carousel-indicator:focus{
  background-color: red;
}
a.home-carousel-indicator:active{ 
  background-color: red;
}
a.home-carousel-indicator:active{
  background-color: red;
}
.btn-circle{
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .backdrop-layer{
    background-color: rgba(0,0,0,0.4);
    height: 100vh;
    width: 100vw;
    position: fixed;
  }
  .modal-end-placement{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    background: rgba(0,0,0,0.8);
    top: 0;
    display: flex;
    align-items: end;
    justify-content: center;
    z-index: 9999;
  }

  .otp-input{
    width: 50px !important;
    font-size: 28px !important;
    height: 50px !important;
    margin: 10px !important;
    background: #eef4f6;
    border: none !important;
    border-radius: 10px !important;
}

//  styles for views component

.mobile {
  display: block;
  background-color: white;
  height: 100%;
}

.desktop {
  display: none;
}
.desktop-container {
  padding: 100px;
  height: 100vh;
  width: 100%;
  background-color: #fff9f1;
}
.inner-desktop {
  display: flex;
  align-items: center;
  height: 100%;
}
.inner-left-div {
  width: 50%;
}
.inner-right-div {
  width: 50%;
  text-align: center;
}

@media (min-width: 967px) {
  .desktop {
    display: block;
  }

  .mobile {
    display: none;
  }
}

.mockup-phone .display {
  margin-top: 0;
}
.mockup-phone {
  display: inline-block;
  border: 0px solid #444;
  border-radius: 50px;
  background-color: #000;
  padding: 10px;
  margin: 0 auto;
  overflow: hidden;
}
.artboard.phone-1 {
  width: 330px;
  height: 640px;
}

.text-success{
  color: green;
}
.radio{
  border: 1px solid grey;
}

.toggle{
  box-shadow: calc(var(--handleoffset)*-1)0 0 2px white inset,0 0 0 2px white inset;
}